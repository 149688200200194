import { Note2, Info, Blank } from "../constants";

export default class DataService {
  _data = {
    crwebsite: 'https://www.chainrunners.xyz/',
    bootjackproto: 'https://bootjack.run/',
    discord: 'https://discord.gg/2e5DHnBDX3',
    flashrekt: 'https://www.cryptovoxels.com/play?coords=NE@466E,37S',
    items: [
      {
        id: 'about',
        name: 'About.txt',
        icon: Info,
        content: {
          paragraphs: [
            "Welcome! We are a registered Entertainment Provider in compliance with Mega City Ordinance(s) 931x 2002m-z.",
            "We do not condone or support any illegal activity or unauthorized use of The Mega Mobile network. The information contained herein is for entertainment purposes only.",
          ],
        },
      },
      {
        id: 'logs',
        name: 'Logs.txt',
        icon: Note2,
        content: {
          logs: [
            {
              date: 'Etown',
              description:
                'A quaint neighborhood just east of Downtown Mega City, residents of Etown enjoy the conveniences of many locally owned shops and artisans that center around Spacebun Avenue. Mostly comprised of multi-family residences. Long-term community members include the “Etown Gang”, a multi-generational with a strong presence in the district. Crime statistics are low; however, it is well known that the Etowners don’t readily report criminal infractions and oftentimes choose to handle issues on their own. A stroll through Etown is best enjoyed with a cup of signature espresso from Mama Maraj’s Café at the corner of 10th and Bun.',
            },       
            {
              date: 'Doritos Dust',
              description:
                'A highly illegal narcotic regulated by Mega City Ordinance 702a. Doritos Dust was once readily sold and consumed in Mega City before it was outlawed by network decree. Any residents of Mega City caught in possession of Dust face penalties up to life in prison. Sale and transport of Dust could result in network deletion. There is a vibrant and growing underground Doritos Dust trade in Mega City. Dust is brokered by crooked Somnites, bounty pirates, and numerous organize crime syndicates. The Doritos Gang is the most prolific and politically connected. EFFECTS. Dorito’s dust may be insufflated or injected and takes effect almost immediately. Side effects include racing heartbeat, dilated pupils, and paranoia. Users report extreme euphoria and unending energy. Doritos dust is highly addictive and deadly. Current market prices are reported to be fixed via collaborative collusion efforts organized by a secret society comprised of the top members from each of the most powerful crime syndicates in Mega City. ',
            },
            {
              date: 'The Industrial Sector',
              description:
                'Once a bustling neighborhood of burgeoning energy and excitement, the Industrial Sector is now a run-down collection of cavernous shells that once stood for opportunity and wealth. Only a few industries have survived the Extulsion Period. Even the most beloved Chip Factory has now moved their operations to the outer-sectors to take advantage of cheap Invisiblent labor and avoid the Somnite-enforced taxes and syndicate shakedowns that come with doing business in Mega City. There is a small population of genetically modifieds, dust addicts, and other social outcasts that squat on properties in this Sector. Most are not dangerous, though there is a high amount of crime and violence reported by Mega Mobile techs in this area. Rumor is The Chip Co may be opening the old factory again soon…',
            }
          ],
        },
      },
      {
        id: 'missions',
        name: 'Missions.txt',
        icon: Blank,
        content: {
          missions: [
            {
              title: 'Shipping Manifest Hack',
              description:
                'We are tracking comms from The Somnites that indicate shipments of arms and contraband headed for Mega City.',
              objective: 'Hack a manifest without getting caught by Mega Mobile network technicians.',
              tips: 'You will have to hack the cipher to steal a manifest. Try guessing random 5 digit codes. Its different for every network address. Other methods have been reported to be successful but are still unverified.',
              url: 'https://bootjack.run/',
              url2: 'https://twitter.com/bootjackrunner',
            },       
            {
              title: 'ETown Heist',
              description:
                'A box of badass Hack The Planet tshirts fell off the back of a truck in E-Town.',
              objective: 'Go see Cosmo in E-Town for a hookup.',
              tips: 'Window to claim is closed. If you managed to get on the list expect a drop soon.',
              url: 'https://twitter.com/MegaMobileCo/status/1519012141835112449?s=20&t=TpAJQnhQLSraUp1xOraeVw',
              url2: 'https://docs.google.com/spreadsheets/d/1QtAvU2S9K-wbeLcZesXac_ArJOJOOoGedkz1QyTmlfo/edit?usp=sharing',
            },
            {
              title: 'The Chip Factory Heist',
              description:
                'The Somnites have set up shop in a diguised chip factory, but we have a way in. Go see Maraj in The Hideout for further instructions.',
              objective: 'Infiltrate the secret Somnite weapons depot.',
              tips: 'You will need a bootjack manifest and a code from the heist protocol to claim a comic book token.',
              url: 'https://www.renegaderunners.xyz/1',
              url2: 'https://twitter.com/renegadecomics'
            },
          ],
        },
      },
    ],
  };
  
  getItems() {
    return this._data.items.map(({ id, name, icon }) => ({ id, name, icon }));
  }

  getItem(id) {
    return this._data.items.find((x) => x.id === id);
  }

  getProjectInfo() {
    return {
      crwebsite: this._data.crwebsite,
      bootjackproto: this._data.bootjackproto,
      discord: this._data.discord,
      flashrekt: this._data.flashrekt,
    };
  }
}
