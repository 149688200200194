import React, { useContext } from 'react'
import DataContext from '../contexts/dataContext'
import { TaskBar, List } from '@react95/core'
import styled from 'styled-components'

const Link = styled.a`
    text-decoration: none;
    color: inherit;
`


function Taskbar() {
    const { crwebsite, bootjackproto, discord, flashrekt } = useContext(DataContext).getProjectInfo();
    console.log(crwebsite, bootjackproto, discord, flashrekt)
    return (
        <TaskBar
            list={
                <List>
                    <List.Item className="pointer" icon="brush">
                        <Link href={bootjackproto} target="_blank">Bootjack Protocol</Link>
                    </List.Item>
                    <List.Divider />
                    <List.Item className="pointer" icon="folder_file">
                        <Link href={crwebsite} target="_blank">Chain Runners</Link>
                    </List.Item>
                    <List.Item className="pointer" icon="folder_file">
                        <Link href={discord} target="_blank">The Hideout</Link>
                    </List.Item>
                    <List.Item className="pointer" icon="folder_file">
                        <Link href={flashrekt} target="_blank">Flashrekt's Compound</Link>
                    </List.Item>
                </List>
            }
        />
    )
}

export default Taskbar
