import React from 'react'
import { Desktop, Notepad2, Notepad, Explorer101, InfoBubble, MediaCd, Awfext326049, BlankScreen100 } from '@react95/icons'

export const Note2 = <Notepad2 />;
export const Note = <Notepad variant="16x16_4"/>
export const Explorer = <Explorer101 variant="32x32_4"/>;
export const Explorer2 = <Explorer101 variant="16x16_4"/>;
export const Info = <InfoBubble variant="32x32_4"/>;
export const Media = <MediaCd />;
export const Awfex = <Awfext326049 />;
export const Monitor = <Desktop />;
export const Blank = <BlankScreen100 variant="32x32_4"/>;

