import React from 'react'
import styled from 'styled-components'
import { Explorer, Media } from '../constants';
import {startWebamp} from '../utils/startWebamp';

const StyledShorcut = styled.div`
    margin-left: 20px;
    margin-top: 20px;
	align-items: center;
`;

function Shortcuts({ openExplorer }) {
    return (
        <div>
            <StyledShorcut>
                <span
                    className="pointer"
                    onClick={() => openExplorer()}>
                    {Explorer}
                </span>
                <div>Explorer</div>
            </StyledShorcut>
            <StyledShorcut>
                <span
                    className="pointer"
                    onClick={()=>startWebamp()}>
                    {Media}
                </span>
                <div>Media</div>
            </StyledShorcut>
        </div>
    )
}

export default Shortcuts
