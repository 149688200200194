import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
    margin-bottom: 10px;
    margin-right: 15px;
    font-size: 1.1em;
`

function Logs({ content }) {
    const { logs } = content;
    return (
        <div>
            <h2>Logs</h2>
            {
                logs.map((log, idx) => (
                    <div key={idx}>
                        <h3>{log.date}</h3>
                        <p>{log.description}</p>
                        <hr />
                    </div>
                ))
            }

        </div>
    )
}

export default Logs
