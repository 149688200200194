import React from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
    margin-bottom: 10px;
    margin-right: 15px;
    font-size: 1.1em;
`

function Missions({ content }) {
    const { missions } = content;
    return (
        <div>
            <h2>Missions</h2>
            {
                missions.map((mission, idx) => (
                    <div key={idx}>
                        <h3>{mission.title}</h3>
                        <p>{mission.description}</p>
                        {
                            mission.objective &&
                            <p><strong>Mission Objective:</strong> {mission.objective}</p>
                        }
                        <p><strong>Tips:</strong> {mission.tips}</p>
                        {
                            mission.url &&
                            <StyledLink href={mission.url} target="__blank">Primary Link</StyledLink>
                        }
                        {
                            mission.url2 &&
                            <StyledLink href={mission.url2} target="__blank">Aux Link</StyledLink>
                        }
                        <hr />
                    </div>
                ))
            }

        </div>
    )
}

export default Missions
